import { featureFlags } from '../exports';
import { ClaimEvidence } from './models/claim-evidence';
import { Document } from './models/document';
import { MyClaim } from './models/my-claim';

export type ClaimListType = 'Active' | 'Previous';

export enum ClaimIncapacityType {
  Illness = 'illness',
  Injury = 'injury',
}

export enum ClaimStatus {
  Notification = 'Notification',
  RequestEvidence = 'RequestEvidence',
  Assessment = 'Assessment',
  Refer = 'Refer',
  Decision = 'Decision',
  Close = 'Close',
}

export const claimGuideType = 'CLAIM_GUIDE';

export function getActiveClaims(claims: MyClaim[]): MyClaim[] {
  return claims.filter((claim) => claim.assessmentStatus !== ClaimStatus.Close.toLowerCase());
}

export function getPreviousClaims(claims: MyClaim[]): MyClaim[] {
  return claims.filter((claim) => claim.assessmentStatus === ClaimStatus.Close.toLowerCase());
}

export function getLatestClaimGuideDocumentFromEvidences(evidences: ClaimEvidence[]): Document | undefined {
  return evidences?.find(
    (evidence) => evidence.evidenceType === claimGuideType,
  )?.documents?.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()).at(0);
}

export function getFeatureFlag(flag: string) {
  return featureFlags?.[flag as keyof typeof featureFlags] ?? undefined;
}
