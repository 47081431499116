import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FileUpload from '../../../../components/file-upload';
import { ClaimEvidence } from '../../../../services/models/claim-evidence';
import useUploadEvidence from '../../../../hooks/use-upload-evidence';
import { MyClaim } from '../../../../services/models/my-claim';
import FileList from './file-list';

export interface EvidenceUploadProps {
  claim: MyClaim,
  evidence: ClaimEvidence,
}

function EvidenceUpload({
  claim,
  evidence,
}: EvidenceUploadProps) {
  const [uploadDocument, { uploading }] = useUploadEvidence();
  const [files, setFiles] = useState<File[]>([]);

  const handleFileSelect = async (newFiles: File[]) => {
    try {
      const file = newFiles[0];
      const document = await uploadDocument(
        file,
        evidence,
        claim,
      );
      if (!document) {
        throw new Error('Document not uploaded');
      }
      setFiles((state) => [...state, file]);
    } catch {
      toast('Error uploading document', {
        position: 'bottom-left',
        type: 'error',
      });
    }
  };

  return (
    <>
      <FileUpload onFileSelect={handleFileSelect} loading={uploading} />
      <FileList files={files} />
    </>
  );
}

export default EvidenceUpload;
