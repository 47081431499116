import { saveAs } from 'file-saver';
import { AxiosResponse } from 'axios';
import http, { instance } from './http';
import { MyDocument } from './models/my-document';
import { EvidenceDocuments } from './models/evidence-documents';
import { EvidenceDocumentResultDto } from './models/evidence-document-result-dto';
import { FileResponseDto } from './models/file-response-dto';
import { EvidenceDocumentRequestDto } from './models/evidence-document-request-dto';
import { getFileExtensionFromContentDisposition } from '../routes/document/document.utils';
import { GenerateEvidenceDocumentWithSignatureDto } from './models/generate-evidence-document-with-signature-dto';
import { getExtensionForType } from './file-helper';

async function getMemberDocuments(): Promise<MyDocument[]> {
  return http.get('/api/v1/members/documents');
}

async function getDocument(documentId: string): Promise<AxiosResponse<Blob>> {
  return instance.get(`/api/v1/documents/${documentId}`, { responseType: 'blob' });
}

async function downloadMemberDocument(downloadRequest: Promise<any>, documentId: string) {
  const response = await downloadRequest;
  const fileExt = getFileExtensionFromContentDisposition(response.headers['content-disposition']);
  const fileName = `${documentId}${fileExt}`;
  saveAs(response.data, fileName);
}

async function downloadDocument(downloadRequest: Promise<any>, fileName?: string) {
  try {
    const response = await downloadRequest;
    saveAs(response.data, fileName);
  } catch {
    throw new Error('Document download failed.');
  }
}

async function openMemberDocument(downloadRequest: Promise<any>, fileName?: string) {
  try {
    const response = await downloadRequest;
    saveAs(response.data, fileName);
  } catch {
    throw new Error('Document download failed.');
  }
}

async function saveBase64AsFile(base64String: string, fileName: string, mimeType: string) {
  const byteCharacters = atob(base64String);
  const blob = new Blob([byteCharacters], { type: mimeType });
  saveAs(blob, fileName);
}

async function getGuideToClaimingDocument(claimId: string): Promise<FileResponseDto> {
  return instance.get(`/api/v1/bff/claims/${claimId}/documents/guideToClaiming`, { responseType: 'blob' });
}

async function uploadEvidenceDocument(claimId: string, evidenceId: string, data: EvidenceDocumentRequestDto): Promise<EvidenceDocumentResultDto> {
  return http.post(`/api/v1/bff/claims/${claimId}/evidences/${evidenceId}/documents`, data);
}

async function updateEvidenceDocuments(claimId: string, evidenceId: string, data: EvidenceDocuments): Promise<void> {
  return http.put(`/api/v1/members/claims/${claimId}/evidences/${evidenceId}/documents`, data);
}

async function signEvidenceDocument(
  evidenceId: string,
  data: GenerateEvidenceDocumentWithSignatureDto,
): Promise<void> {
  return http.post(`/api/v1/bff/evidences/${evidenceId}/documents/signed`, data);
}

async function getPreSignedUrl(documentNames: string[]): Promise<Record<string, string>> {
  return http.post('/api/v1/bff/presignedurls', documentNames);
}

async function uploadFileWithUrl(url: string, file: File) {
  return fetch(url, { method: 'PUT', headers: { 'Content-type': file.type, 'x-amz-tagging': 'applicationSource=memberportal' }, body: file });
}

function getStaticGuideToClaimingDocument() {
  window.open('https://dyn.the-exeter.com/download/brochure?code=CG#:~:text=The only financial evidence you,of your illness or injury.', '_blank');
}

function getFileNameFromContentDisposition(disposition: string | null | undefined): string | null {
  if (!disposition) {
    return null;
  }

  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName = null;
  if (utf8FilenameRegex.test(disposition)) {
    const matches = utf8FilenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = decodeURIComponent(matches[1].replace(/^%22|%22$/g, ''));
    }
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    // slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        // eslint-disable-next-line prefer-destructuring
        fileName = matches[2];
      }
    }
  }
  return fileName;
}

function getFileNameFromResponse(response: AxiosResponse<Blob>, defaultName: string): string {
  const contentDisposition = response.headers['content-disposition'];
  const fileName = getFileNameFromContentDisposition(contentDisposition)
    ?? `${defaultName}${getExtensionForType(response.data.type)}`;
  return fileName;
}

const documentApi = {
  downloadDocument,
  downloadMemberDocument,
  getDocument,
  getGuideToClaimingDocument,
  getFileNameFromResponse,
  getPreSignedUrl,
  getMemberDocuments,
  getStaticGuideToClaimingDocument,
  openMemberDocument,
  saveBase64AsFile,
  signEvidenceDocument,
  uploadEvidenceDocument,
  updateEvidenceDocuments,
  uploadFileWithUrl,
};

export default documentApi;
